import DoddnetApi from "../apis/doddnet.api";

export const getJob = async (jobId, includeCost = false) => {
    if(!jobId) return;
    try {
        const {data} = await DoddnetApi.get(`/v1/property-care/site/job`, {
            params: {
                jobId,
                includeCost
            }
        });
        return data;
    } catch (error) {
        console.error(error);   
    }
}

export const getJobDocuments = async (jobNo, subJobNo, client, companyNumber) => {
    if(!jobNo) return;
    if(!subJobNo) return;
    if(!client) return;
    if(!companyNumber) return;
    try {
        const {data} = await DoddnetApi.get(`/v1/property-care/site/job/documents`, {
            params: {
                jobNo,
                subJobNo,
                client,
                companyNumber
            }
        });
        return data;
    } catch (error) {
        console.error(error);   
    }
}

export const getJobPhotos = async (jobId) => {
    if(!jobId) return;
    try {
        const {data} = await DoddnetApi.get(`/v1/property-care/site/job/photos`, {
            params: {
                jobId
            }
        });
        return data;
    } catch (error) {
        console.error(error);   
    }
}

export const getImage = async (imageLocation) => {
    try {
        const {data} = await DoddnetApi.get(`/v1/property-care/image`, {
            params: {
                imageLocation
            }
        });
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const searchClientJobs = async (client, search = "") => {
    if(!client) return [];
    try {
        const {data} = await DoddnetApi.get('/vixen/clients/jobs', {
            params: {
                client: client.clientId,
                job: search,
                companyNumber: client.companyNumber, 
                ignoreSiteIdsBeginningWith: client.settings.ignoreSiteIdsBeginningWith
            }
        });
        return data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const postNewJob = async (job) => {
    try {
        const {data} = await DoddnetApi.post('/v1/vixen/jobs/reactive', job);
        return data;
    } catch (error) {
        console.error(error);
        throw(error);
    }
}

export const updateJobAppointment = async (job) => {
    try {
        const {data} = await DoddnetApi.post('/v1/vixen/update/appointment', job);
        return data;
    } catch (error) {
        console.error(error);
        throw(error);
    }
}