import DoddnetApi from "../apis/doddnet.api";

export const getKpis = async () => {
    try {
        const {data} = await DoddnetApi.get('/v1/property-care/kpis');
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const getKpiData = async (kpiId, params) => {
    try {
        const {data} = await DoddnetApi.get(`/v1/property-care/client/kpis/${kpiId}`, {
            params
        });
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getKpiDrilldownData = async (kpiId, params) => {
    try {
        const {data} = await DoddnetApi.get(`/v1/property-care/client/kpis/${kpiId}/drilldown`, {
            params
        });
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getCustomKpiData = async (params) => {
    try {
        const {data} = await DoddnetApi.get(`/v1/property-care/client/custom-kpi/`, {
            params
        });
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getCustomKpiDrilldownData = async (params) => {
    try {
        const {data} = await DoddnetApi.get(`/v1/property-care/client/custom-kpi/drilldown`, {
            params
        });
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}